<template>
  <el-form class="h-search-bar-wrapper" size="small" :inline="true" :model="droneInfomation">
    <el-form-item label="任务名称">
      <el-input v-model="droneInfomation.name" placeholder="请输入任务名称" clearable @clear="handleClear">
        <el-button slot="append" icon="el-icon-search" @click="submit(droneInfomation.name)">查询</el-button>
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'DSearchBar',
  data() {
    return {
      projectID: '',
      droneInfomation: {
        name: '',
      },
    };
  },
  methods: {
    submit(name) {
      this.$emit('submit', name);
    },
    handleClear() {
      this.submit('');
    },
  },
};
</script>

<style lang="scss" scoped>
.h-search-bar-wrapper {
  ::v-deep .el-form-item {
    margin-bottom: 0;
    .el-form-item__label {
      line-height: 34px;
    }
    .el-input {
      input {
        height: 34px;
      }
    }
    .el-button {
      border: none;
    }
  }
}
</style>
