import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;

function getMissionRecord(parse, size, offset) {
  return request({
    url: `${server}/${base_url.core}/missions/schedules/list?${parse}size=${size}&offset=${offset}`,
    method: 'GET',
  })
}

export {
  getMissionRecord
}