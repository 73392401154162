<template>
  <el-card class="dashboard-wrap page-container">
    <SearchBar slot="header" @submit="submit" />
    <Floor :records="records" :tHeight="Height" />
    <PaginationBar :table-config="tableConfig" @change:pageNumber="handleCurrentChange" />
  </el-card>
</template>

<script>
import SearchBar from './components/SearchBar.vue';
import Floor from './components/Floor.vue';
import PaginationBar from '@/components/Pagination/index.vue';
import { getMissionRecord } from '@/api/record';
import { getFlightRecord } from '@/api/flight';

import { mapGetters } from 'vuex';
import statusInclude from '@/utils/statusCode';
import Mixin from '../deviceManagement/Mixin';

export default {
  name: 'Dashboard',
  components: {
    SearchBar,
    Floor,
    PaginationBar,
  },
  mixins: [Mixin],
  data() {
    return {
      records: [],
      tableConfig: {
        page: 1,
        size: 20,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters([]),
  },
  watch: {},
  created() {
    this.getHeight();
    this.getFlightRecord({ ...this.tableConfig });
  },
  mounted() {
    window.addEventListener('resize', this.getHeight);
  },
  methods: {
    getHeight() {
      this.Height = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },
    goBack() {
      this.$store.commit('app/OPEN_ACTIVE', false);
    },
    handleCurrentChange(page) {
      this.tableConfig.page = page;
      this.getFlightRecord({ ...this.tableConfig });
    },

    createMission() {
      this.isVisible = true;
    },
    async submit(name) {
      this.getFlightRecord({ ...this.tableConfig, name });
    },
    async getFlightRecord({ page = 1, size = 13, name }) {
      const { code, data } = await getFlightRecord(page, size, name);
      if (statusInclude(code)) {
        const { total, list } = data;
        this.records = list;
        this.tableConfig.total = total || this.tableConfig.total;
      }
    },
  },
  beforeDestroy() {
    //移除size监听事件
    window.removeEventListener('resize', this.getHeight);
  },
};
</script>

<style lang="scss" scoped>
.dashboard-wrap {
  ::v-deep .el-card__body {
    height: calc(100vh - #{$offset});
    position: relative;
    .el-row.pagination-bar {
      padding: 5px;
      width: 100%;
      background: #{$cardHeaderBg};
      position: absolute;
      right: 0;
      bottom: 0;
      .el-pagination {
        float: right;
      }
    }
  }
}
</style>
