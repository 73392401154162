<template>
  <el-table ref="filterTable" :data="records" :height="tHeight">
    <el-table-column type="index" width="50" label="序号" align="center"></el-table-column>
    <el-table-column label="任务批次号" align="center" width="210px">
      <template slot-scope="scope">
        <el-link type="primary" @click="$router.push({ name: 'HistoricalInformation', query: { siteID: scope.row.siteID, missionBatch: scope.row.missionBatch } })">{{ scope.row.missionBatch }}</el-link>
      </template>
    </el-table-column>
    <el-table-column prop="missionName" label="任务名称" align="center" :show-overflow-tooltip="true"></el-table-column>
    <el-table-column prop="siteName" label="站点名称" align="center" width="180px"></el-table-column>
    <el-table-column prop="UAVID" label="无人机编号" align="center"></el-table-column>
    <el-table-column prop="UAVFlightMileage" label="飞行里程/M" align="center" :show-overflow-tooltip="true"></el-table-column>
    <el-table-column label="完成动作" align="center">
      <template slot-scope="scope">
        <el-tag size="mini" :type="scope.row.finishAction == 1 ? 'success' : ''">{{ scope.row.finishAction == 1 ? '自动返航' : '终点站' }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="finishAction" label="任务状态" align="center">
      <template slot-scope="scope">
        <el-tag size="mini" :type="scope.row.state | missionTagClassName">{{ scope.row.state | missionStatus }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="UAVStartTime" sortable label="起飞时间" align="center" width="160px"></el-table-column>
    <el-table-column prop="UAVEndTime" sortable label="降落时间" align="center" width="160px"></el-table-column>
    <el-table-column prop="createTime" sortable label="创建时间" align="center" width="160px"></el-table-column>
    <el-table-column v-if="false" prop="finishAction" label="操作" align="center">
      <template slot-scope="scope">
        <!-- SITE1663927218464 -->
        <el-button @click="$router.push({ path: '/grid-result', query: { s: scope.row.siteID, m: scope.row.missionBatch } })" type="text">成果</el-button>
        <!-- <el-button @click="$router.push({ path: '/grid-result', query: { s: 'ST_00000017', m: 'A071658368417787554741' } })" type="text">成果</el-button> -->
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'MissionRecord',
  props: {
    records: {
      type: Array,
      default: [],
    },
    tHeight: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style></style>
